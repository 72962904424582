<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <span>Rapports > Détails des Redevances sur les Patentes et Vignettes</span>
      </div>
    </div>
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Détails des Redevances sur les Patentes et Vignettes
          </div>
        </div>
      </div>
    </div>
    <!-- Boss annee -->
    <div class="row">
      <form class="form-inline mx-auto">
        <div class="form-group">
          <label for="">Période du: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_debut"/>
        </div>
        <div class="form-group">
          <label for="">au: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_fin"/>
        </div>
        <div class="form-group">
          <button type="button"
                  class="btn btn-info"
                  @click="changeYear()">
            Rechercher
          </button>
        </div>
      </form>
    </div>
    <!-- END of boss annee  -->
    <!-- Vue-html2pdf -->
    <vue-html2pdf :show-layout="false"
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="true"
                  :paginate-elements-by-height="1700"
                  filename="Rapport_patente_vignette"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="landscape"
                  pdf-content-width="1000px"
                  @progress="onProgress($event)"
                  @hasStartedGeneration="hasStartedGeneration()"
                  @hasGenerated="hasGenerated($event)"
                  ref="html2Pdf"> 
      <section slot="pdf-content">
        <div class="row justify-content-center my-4">
          <div class="col-8">
            <div class="card title-card">
              <div class="card-body text-center title">
                Détails des Redevances sur les Patentes et Vignettes
              </div>
            </div>
          </div>
        </div>
        <div class="row" 
             style="padding-left:90px;">
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="th-blue text-center">Mois</th>
                <th class="th-blue text-right">Montant (FCFA) du {{ year.annee_debut }} au {{ year.annee_fin }}</th>
                <th class="th-blue text-right">Montant (FCFA) précédant le {{ year.annee_debut }}</th>
                <th class="th-blue text-right">Variations</th>
              </tr>
            </thead>
            <tbody v-if="ressources != null">
              <tr v-for="(ressource, rekey) in ressources"
                  :key="rekey"
                  class="text-center">
                <td>{{ ressource.mois }}</td>
                <td class="text-right">{{ Math.round(Number(ressource.annee)).toLocaleString() }}</td>
                <td class="text-right">{{ Math.round(Number(ressource.annee_n1)).toLocaleString() }}</td>
                <td class="text-right">{{ (Math.round(Number(ressource.variation) * 100) / 100).toLocaleString() }}%</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr class="text-center">
                <td colspan="4">Aucune donnée chargée...</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section> 
    </vue-html2pdf>
    <!-- END of Vue-html2pdf  -->
    <div class="row mt-4">
      <div class="col-md-2">
        <button class="btn btn-round btn-blue"
                @click="generateReport()"> Imprimer </button>
      </div>
    </div>

    <div class="row">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="th-blue text-center">Mois</th>
            <th class="th-blue text-right">Montant (FCFA) du {{ year.annee_debut }} au {{ year.annee_fin }}</th>
            <th class="th-blue text-right">Montant (FCFA) précédant le  {{ year.annee_debut }}</th>
            <th class="th-blue text-right">Variations</th>
          </tr>
        </thead>
        <tbody v-if="ressources != null">
          <tr v-for="(ressource, rekey) in ressources"
              :key="rekey"
              class="text-center">
            <td>{{ ressource.mois }}</td>
            <td class="text-right">{{ Math.round(Number(ressource.annee)).toLocaleString() }}</td>
            <td class="text-right">{{ Math.round(Number(ressource.annee_n1)).toLocaleString() }}</td>
            <td class="text-right">{{ (Math.round(Number(ressource.variation) * 100) / 100).toLocaleString() }}%</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="text-center">
            <td colspan="4">Aucune donnée chargée...</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<style>
  form input[type="text"], 
form input[type="date"], 
form input[type="number"], 
form input[type="file"], 
form textarea,
form select option,
form select.form-control,
.btn-info,
span[slot="no-options"].text-danger{
  font-size: 0.6em;
}
form select.form-control option{
  font-size: 1em;
}
input.vs__search,table.table-striped{
  font-size: 0.7em;
}
form.form_default input[type="text"], 
form.form_default input[type="date"], 
form.form_default input[type="number"], 
form.form_default input[type="file"], 
form.form_default textarea{
  height: fit-content;
}
/* div.vs__dropdown-toggle,form select.form-control{
  height: 3.5vh;
} */

@media only screen and (width > 1466px){
  div.vs__dropdown-toggle,form select.form-control{
  height: 2.5vh;
  }
}
/* form.form-inline input,
form.form-inline select, */
li.nav-item
/* form.form-inline button */{
  font-size: 0.6em;
}
</style>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex"
import VueHtml2pdf from 'vue-html2pdf'
export default {
  name: "RctAffectee",
  components:{
    VueHtml2pdf
  },
  props: ["extYear"],
  data: () => ({
    annee_debut: new Date().getFullYear()+"-01-01",
    annee_fin: new Date().getFullYear()+"-12-31",
    year:{
      annee_debut: new Date().getFullYear()+"-01-01",
      annee_fin: new Date().getFullYear()+"-12-31",
    },
    yearData: null,
    ressources: null,
    pourcentage: 0,
  }),
  created() {
    this.RessourcesAffectees({ annee: this.year })
    // this.loadRessourceAf(this.year)
    // this.loadRessourceAfv(this.year)
  },
  watch: {
    // arRessource() {
    //   this.yearData = this.arRessource
    //   this.pourcentage = (Number(this.yearData.totaux.flux_recus) * 100) / Number(this.yearData.totaux.budget_cible)
    //   if(isNaN(this.pourcentage)){
    //     this.pourcentage=0
    //   }
    // },
    // arRessourcev() {
    //   this.ressources = this.arRessourcev
    // },
    ressourcesAffectees() {
      if (this.ressourcesAffectees.analyse.libelle) {
        console.log(this.ressourcesAffectees)
        this.yearData = this.ressourcesAffectees.analyse
        this.ressources = this.ressourcesAffectees.tableau
        this.pourcentage =
          (this.yearData.total_flux * 100) / this.yearData.budget_cible
      }
    },
    // extYear() {
    //   if (this.extYear) {
    //     this.changeYear(this.extYear)
    //     console.log(this.extYear)
    //   }
    // },
  },
  computed: {
    ...mapGetters(["ressourcesAffectees"]),
  },
  methods: {
    ...mapActions(["RessourcesAffectees"]),
    ...mapMutations([
      "setRessourcesAffectees",
      "setMsgFailRessourcesAffectees",
    ]),
    changeYear() {
      this.setRessourcesAffectees("")
      this.year.annee_debut= this.annee_debut
      this.year.annee_fin= this.annee_fin

      this.RessourcesAffectees(this.year)
        
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    }
  },
}
  </script>
    